import pulse from '@finn-no/pulse-sdk';
import '@warp-ds/icons/elements/sofa-42';
import '@warp-ds/icons/elements/link-external-16';
import '@warp-ds/icons/elements/chevron-right-16';
import '@warp-ds/icons/elements/job-42';
import '@warp-ds/icons/elements/minivan-42';
import '@warp-ds/icons/elements/real-estate-42';
import '@warp-ds/icons/elements/airplane-42';
import '@warp-ds/icons/elements/sailboat-42';
import '@warp-ds/icons/elements/motorcycle-42';
import '@warp-ds/icons/elements/cabin-42';
import '@warp-ds/icons/elements/phone-badge-check-42';
import '@warp-ds/icons/elements/honk-light-42';
import '@warp-ds/icons/elements/nettbil-light-42';
import '@warp-ds/icons/elements/remppatori-light-42';
import '@warp-ds/icons/elements/chair-42';
import '@warp-ds/icons/elements/shirt-42';
import '@warp-ds/icons/elements/stroller-42';
import '@warp-ds/icons/elements/ice-skater-42';
import '@warp-ds/icons/elements/paw-42';
import '@warp-ds/icons/elements/guitar-bat-42';
import '@warp-ds/icons/elements/oikotie-light-42';
import '@warp-ds/icons/elements/car-front-42';
import '@warp-ds/icons/elements/smart-phone-42';
import '@warp-ds/icons/elements/tools-42';
import '@warp-ds/icons/elements/car-part-42';
import '@warp-ds/icons/elements/vase-42';
import '@warp-ds/icons/elements/autovex-light-42';
import '@warp-ds/icons/elements/geometric-shapes-42';

// Get div parameters used for fadeout scroll setup.
function getScrollParameters(element) {
    const { scrollLeft, scrollWidth, clientWidth } = element;

    const width = scrollWidth - clientWidth;

    const fraction = (width - scrollLeft) / 50;

    return { width, fraction, scrollLeft };
}

// Fade out the gradient div as the category scroll position approaches the end.
// This is only visible on mobile devices.
function setupMPNBGradientBehavior() {
    const gradientDiv = document.querySelector('.overflow-gradient');

    const categoryDiv = document.querySelector('.scrollable-container>div');

    if (gradientDiv && categoryDiv) {
        // Setup scrolling.
        categoryDiv.addEventListener('scroll', (e) => {
            const { width, fraction, scrollLeft } = getScrollParameters(e.target);

            // Let the opacity approach 0 as the scroll position moves to the end.
            if (fraction <= 1) {
                gradientDiv.style.opacity = fraction;
            }

            // Use scroll left to reset the opacity when the scroll position is below a threshold, to ensure opacity reset during quick scrolling to the left.
            if (scrollLeft / width < 0.7) {
                gradientDiv.style.opacity = 1;
            }
        });
    }
}

function setupCategoryGradientBehavior() {
    const gradientDiv = document.querySelector('.categories-overflow-gradient');

    const categoryDiv = document.querySelector('.categories-scrollable-container>div');

    if (gradientDiv && categoryDiv) {
        // Setup scrolling.
        categoryDiv.addEventListener('scroll', (e) => {
            const container = document.querySelector('.categories-scrollable-container');
            container.setAttribute('style', 'margin: 0 -1.6rem');

            const { width, fraction, scrollLeft } = getScrollParameters(e.target);

            if (fraction <= 1) {
                gradientDiv.style.opacity = fraction;
            }

            if (scrollLeft / width < 0.8) {
                gradientDiv.style.opacity = 1;
            }

            if (scrollLeft === 0) {
                container.removeAttribute('style');
            }
        });
    }
}

// Add tracking to MPNB links and the Explore more button if present.
function addTrackingToMarketLinks() {
    const elements = [...document.querySelectorAll('[data-markets-pulse-wrapper] a')];

    const exploreMore = document.querySelector('[data-explore-more-button]');

    if (exploreMore) {
        elements.push(exploreMore);
    }

    elements.forEach((element) => {
        const tracking = element.getAttribute('data-tracking-details');

        if (tracking) {
            const trackingData = JSON.parse(window.atob(tracking));

            element.addEventListener('click', () => {
                pulse.trackEvent({
                    type: 'Click',
                    intent: 'Open',
                    name: trackingData.name,
                    object: {
                        id: trackingData.object.id,
                        type: 'UIElement',
                        elementType: trackingData.object.elementType,
                        position: trackingData.object.position,
                    },
                    target: trackingData.target,
                    vertical: {
                        '@type': trackingData.vertical.type,
                        name: trackingData.vertical.name,
                    },
                });
            });
        }
    });
}

function trackCategorySection() {
    document.querySelectorAll('[data-categories-pulse-wrapper] a').forEach((element) => {
        const tracking = element.getAttribute('data-tracking-category');
        const trackingData = JSON.parse(window.atob(tracking));
        element.addEventListener('click', () => {
            pulse.trackEvent({
                type: 'Click',
                intent: 'Open',
                name: trackingData.name,
                object: {
                    type: 'UIElement',
                    elementType: trackingData.object.element_type,
                    name: trackingData.object.name,
                },
                target: trackingData.target,
                vertical: {
                    '@type': trackingData.vertical.type,
                    name: trackingData.vertical.name,
                },
            });
        });
    });
}

function main() {
    if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/sw.js').catch(() => {});
    }

    window.addEventListener('DOMContentLoaded', () => {
        pulse.trackPageView({
            object: {
                type: 'Frontpage',
            },
            vertical: {
                name: 'common_pages',
            },
        });

        addTrackingToMarketLinks();
        trackCategorySection();
        setupMPNBGradientBehavior();
        setupCategoryGradientBehavior();
    });
}

main();
